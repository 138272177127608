<template>
  <mo-system>
    <TopBar @navClick="toggleSideDrawer"></TopBar>
    
    <ContentsArea id="contentsArea">
      <router-view />
    </ContentsArea>

    <SideDrawer ref="sideDrawer"></SideDrawer>
  </mo-system>
  
</template>

<script>
import TopBar from './commons/TopBar.vue'
import ContentsArea from './commons/ContentsArea.vue'
import SideDrawer from './commons/SideDrawer.vue'

export default {
  name: 'MobileFrame',
  components: {TopBar, ContentsArea, SideDrawer},
  data() {
    return {
      drawer: false,
    }
  },
  methods: {
    toggleSideDrawer() {
      this.$refs.sideDrawer.toggleSideDrawer();
    }
  }
};
</script>

<style scoped>
</style>